import React from "react"
import queryString from "query-string"

import { Timeline, Icon, Col, Row, message } from "antd"
import { injectIntl, navigate } from "gatsby-plugin-intl"

import formatService from "../../services/formatService"
import Template from "../../layouts/base"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import withAuth from "../../components/withAuthHoc"
import authFetch from "../../services/network"

const REQUEST_INTERVAL_MILIS = 5000

class IngestCreating extends React.Component {
  constructor(props) {
    super(props)

    const _thatIntl = this.props.intl
    this.intervalId = null
    this.requestInFlight = false
    this.ingestNamesTypes = {
      REAL_TIME: _thatIntl.formatMessage({ id: "new-ingestion-rt-title" }),
      NEAR_REAL_TIME: _thatIntl.formatMessage({
        id: "new-ingestion-nrt-title",
      }),
      BATCH: _thatIntl.formatMessage({ id: "new-ingestion-batch-title" }),
      METADATA: _thatIntl.formatMessage({ id: "new-ingestion-metadata-title" }),
    }
    const args = queryString.parse(props.location.search)
    this.codeIngestType = args.t

    this.state = {
      timeline: [
        {
          label: _thatIntl.formatMessage({
            id: "new-ingestion-creating-label-step-1",
          }),
          timestamp: new Date().toISOString(),
        },
        {
          label: _thatIntl.formatMessage({
            id: "new-ingestion-creating-label-step-2",
          }),
          timestamp: new Date().toISOString(),
        },
      ],
      id: args.id,
    }
  }

  componentDidMount() {
    this._fetchStatusPeriodically()
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.requestInFlight = false
  }

  _fetchStatusPeriodically() {
    this.intervalId = setInterval(() => {
      if (this.requestInFlight === false) {
        this._fetchStatus()
      }
    }, REQUEST_INTERVAL_MILIS)
  }

  _fetchStatus() {
    this.requestInFlight = true

    authFetch(
      `${process.env.GATSBY_CONF_API_URL}/ingests/${this.state.id}/status/`
    )
      .then(response => {
        const { status } = response

        if (status !== 200 && status !== 405) {
          this._onFetchStatusError(status)
        } else {
          this._onFetchStatusOk(response)
        }
      })
      .catch(reason => {
        clearInterval(this.intervalId)
        console.error(`[ERROR]: Fetching status ${reason}`)
      })
  }

  _onFetchStatusError(errorCode) {
    console.error(`[ERROR]: Fetching /status: Code: ${errorCode}`)
    message.error(_thatIntl.formatMessage({ id: "error-fetching-data" }))

    clearInterval(this.intervalId)
  }

  _onFetchStatusOk(response) {
    const _thatIntl = this.props.intl

    response.json().then(({ item = {} }) => {
      const { id, currentStatus, action, updatedAt } = item
      const ingestCreationIsCompleted =
        ["INGEST_STATUS_OK", "INGEST_STATUS_KO"].includes(currentStatus) &&
        typeof window !== "undefined" &&
        window.location &&
        window.location.search

      if (ingestCreationIsCompleted) {
        clearInterval(this.intervalId)

        const urlParams = new URLSearchParams(window.location.search)
        const accumulator = urlParams.get("accumulator")
        let url = `/ingest/result?id=${id}&status=${currentStatus}&t=${this.codeIngestType}`

        url =
          accumulator && accumulator === "true"
            ? `${url}&accumulator=true`
            : url

        navigate(url)
      } else {
        try {
          const timeline = this.state.timeline.slice()
          const label = _thatIntl.formatMessage({ id: action })

          if (timeline.slice(-1)[0].label !== label) {
            timeline.push({
              label,
              timestamp: new Date(updatedAt).toISOString(),
            })
          }

          this.setState({ timeline })
        } catch (err) {
          /**
           *  Ignore silently since these are errors due to polling
           *  in order to not to fill the console with errors
           */
        } finally {
          this.requestInFlight = false
        }
      }
    })
  }

  render() {
    const _thatIntl = this.props.intl

    return (
      <Template selected={["ingest", "ingest-new"]}>
        <CustomBreadcrumb
          crumbs={[
            _thatIntl.formatMessage({ id: "models-ingestion" }),
            _thatIntl.formatMessage({
              id: "new-ingestion-breadcrumb-creating",
            }),
            `${this.ingestNamesTypes[this.codeIngestType]}`,
          ]}
        />

        <Row>
          <Col className="intro">
            <h1>
              {_thatIntl.formatMessage({ id: "new-ingestion-creating-header" })}
            </h1>
          </Col>
        </Row>

        <div className="content">
          <p>
            {_thatIntl.formatMessage({ id: "new-ingestion-creating-info" })}
          </p>

          <Row
            type="flex"
            justify="center"
            align="top"
            style={{ marginTop: 24 }}
          >
            <Col span={24}>
              <Timeline>
                {this.state.timeline.map((item, index) => (
                  <Timeline.Item key={index} color="green">
                    {formatService.formatDate("short", item.timestamp)} -{" "}
                    {item.label}
                  </Timeline.Item>
                ))}
              </Timeline>
              <Icon type="loading" style={{ fontSize: 24 }} spin />
            </Col>
          </Row>
        </div>
      </Template>
    )
  }
}

export default injectIntl(withAuth(IngestCreating))
