export default {
  formatDate: (format, value) => {
    let reply = ""

    try {
      const options = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }

      if (format === "long") options.timeZoneName = "long"

      reply = new Intl.DateTimeFormat("es-ES", options).format(new Date(value))
    } catch (e) {
      reply = "Unparseable date"
    }
    return reply
  },
}
